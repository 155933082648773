import { baseUrl, asyncRequest } from 'utils/http/api';
import { ConfigState } from 'types/app/config';
import { ENDPOINT_URLS } from 'constants/endpointUrls';

export const getConfig = async (): Promise<ConfigState> =>
  await asyncRequest({
    method: 'GET',
    baseURL: baseUrl,
    url: ENDPOINT_URLS.CONFIG.ROOT,
  });
