import { asyncRequest, baseUrl } from 'utils/http/api';
import { ENDPOINT_URLS } from 'constants/endpointUrls';
import { ReservationSettingsState } from 'types/app/reservationSettings';

export const getSettingsCall = async () => {
  return await asyncRequest({
    method: 'GET',
    baseURL: baseUrl,
    url: ENDPOINT_URLS.RESTAURANT.SETTINGS.CALENDAR,
  });
};

export const updateReservationSettingsCall = async (data: ReservationSettingsState) => {
  return await asyncRequest({
    method: 'PUT',
    data,
    baseURL: baseUrl,
    url: ENDPOINT_URLS.RESTAURANT.SETTINGS.CALENDAR,
  });
};
