import { baseUrl, asyncRequest } from 'utils/http/api';
import { ENDPOINT_URLS } from 'constants/endpointUrls';

export const fileCall = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  return await asyncRequest({
    method: 'POST',
    data: formData,
    baseURL: baseUrl,
    url: ENDPOINT_URLS.FILE.ROOT,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getFileCall = async (url: string) => {
  return await asyncRequest({
    method: 'GET',
    baseURL: baseUrl,
    responseType: 'blob',
    url: url,
  });
};
