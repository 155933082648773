import { request, baseUrl } from 'utils/http/api';

export interface LoginParams {
  email: string;
  password: string;
  tfa_code?: number;
}

export interface ResetPasswordParams {
  email: string;
}

export function getUserSettingsCall() {
  return request({ method: 'get', url: `application/user-settings` }).then((response) => response);
}

export function loginCall(data: LoginParams) {
  return request({
    method: 'POST',
    data,
    baseURL: baseUrl,
    url: '/api/auth/login',
  }).then((response) => response);
}

export function getAuthenticatedUserCall() {
  return request({
    method: 'GET',
    url: '/auth/authenticated-user',
  }).then((response) => response);
}

export function logoutCall() {
  return request({
    method: 'POST',
    url: '/auth/logout',
  }).then((response) => response);
}

export function resetPasswordCall(data: ResetPasswordParams) {
  return request({
    method: 'POST',
    data,
    baseURL: baseUrl,
    url: '/api/auth/request-password-reset',
  }).then((response) => response);
}

export function changeRestaurantCall(id: string) {
  return request({
    method: 'POST',
    url: `/auth/change-restaurant/${id}`,
  }).then((response) => response);
}

export function resendTfaCall(data: LoginParams) {
  return request({
    method: 'POST',
    data,
    baseURL: baseUrl,
    url: '/api/auth/tfa-resend',
  }).then((response) => response);
}
