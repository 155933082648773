import { Suspense } from 'react';
import NotificationsProvider from './components/Notifications/NotificationsProvider';
import getDesignTokens from '../mui/theme';
import { TokenProvider } from './components/TokenProvider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { RouterProvider } from 'react-router-dom';
import { useThemeMode } from 'hooks/useThemeMode';
import router from 'app/router/router';

const App = () => {
  const { mode } = useThemeMode();
  // @ts-ignore
  const theme = createTheme(getDesignTokens(mode));

  return (
    <TokenProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <NotificationsProvider>
          <Suspense fallback={''}>
            <RouterProvider router={router} />
          </Suspense>
        </NotificationsProvider>
      </ThemeProvider>
    </TokenProvider>
  );
};

export default App;
