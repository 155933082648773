import { request } from 'utils/http/api';
import moment from 'moment-timezone';
import axios, { Canceler } from 'axios';
// import { BlockReservationHours } from '../app/components/OpeningHours'
import { clean } from 'utils/data-processors/object';
import { AdvancedSearchParams } from 'types/calendar';
import { ReservationState } from 'types/reservation';

// let getReservationsCancel: Canceler,
let searchReservationsCancel: Canceler;

export function getLocaleCall() {
  return request({ method: 'GET', url: '/calendar/locale' }).then((response) => response);
}

export function getTablesCall() {
  return request({ method: 'get', url: `calendar/restaurant/rooms` }).then((response) => response);
}

export function getReservationsCall(startDate: string, endDate: string, params: any) {
  return request({
    method: 'get',
    url: `calendar/restaurant/reservations/${startDate}/${endDate}`,
    params,
  }).then((response) => response);
}

export function searchReservationsCall(params: AdvancedSearchParams) {
  const CancelToken = axios.CancelToken;

  searchReservationsCancel && searchReservationsCancel();

  return request({
    method: 'get',
    url: `calendar/restaurant/search-reservations`,
    params: clean(params),
    cancelToken: new CancelToken(function executor(c) {
      searchReservationsCancel = c;
    }),
  }).then((response) => response);
}

export function getRestaurantCall() {
  return request({
    method: 'get',
    url: `calendar/restaurant`,
  }).then((response) => response);
}

export function getWaitersCall() {
  return request({
    method: 'get',
    url: `calendar/restaurant/waiters`,
  }).then((response) => response);
}

export function saveReservationCall(reservation: ReservationState, date_format_key: string) {
  const data = {
    ...reservation,
    startTime: moment(reservation.startTime).utcOffset(0, true),
    endTime: moment(reservation.endTime).utcOffset(0, true),
    dateFormatKey: date_format_key,
  };
  return request({
    method: 'post',
    data: data,
    url: `calendar/restaurant/reservation`,
  }).then((response) => response);
}

export function deleteReservationCall(id: number) {
  return request({
    method: 'delete',
    url: `calendar/restaurant/reservation/${id}`,
  }).then((response) => response);
}

// TODO: change parameters + tableIDS to array
export function changeTimeReservationCall(
  reservationId: string,
  tableIds: string[],
  from: string,
  till: string,
) {
  const data = {
    reservationId,
    tableIds,
    from: moment(from).utcOffset(0, true).format('YYYY-MM-DD HH:mm:ss'),
    till: moment(till).subtract(1, 'second').utcOffset(0, true).format('YYYY-MM-DD HH:mm:ss'),
  };
  return request({
    method: 'post',
    data: data,
    url: `calendar/restaurant/reservation/changeTime`,
  }).then((response) => response);
}
//TODO BlockReservationHours
export function blockReservationHoursCall(data: any) {
  return request({
    method: 'POST',
    data,
    url: '/calendar/restaurant/block-reservation-hours',
  }).then((response) => response);
}

export function searchClientCall(query: string) {
  return request({
    method: 'GET',
    url: `calendar/restaurant/client/search/${query}`,
  }).then((response) => response);
}
