import moment from 'moment';
import { request } from 'utils/http/api';

export function addCommentCall(commentBody: string, reservationId: number) {
  const data = {
    commentBody,
    reservationId,
  };
  return request({
    method: 'post',
    data: data,
    url: `calendar/restaurant/comment`,
  }).then((response) => response);
}

export function editCommentCall(id: number, commentBody: string) {
  const data = {
    commentBody,
  };
  return request({
    method: 'patch',
    data: data,
    url: `calendar/restaurant/comment/${id}`,
  }).then((response) => response);
}

export function deleteCommentCall(id: number) {
  return request({
    method: 'delete',
    url: `calendar/restaurant/comment/${id}`,
  }).then((response) => response);
}

export function setVipCall(isVip: boolean, clientId: number) {
  const data = {
    isVip,
  };
  return request({
    method: 'patch',
    data: data,
    url: `calendar/restaurant/vip/${clientId}`,
  }).then((response) => response);
}

export function setBigSpenderCall(isBigSpender: boolean, clientId: number) {
  return request({
    method: 'PATCH',
    data: {
      isBigSpender,
      clientId,
    },
    url: 'calendar/restaurant/set-big-spender',
  }).then((response) => response);
}

export function getAverageRatingCall(clientId: number) {
  return request({
    method: 'get',
    url: `calendar/restaurant/client/${clientId}/feedback`,
  }).then((response) => response);
}

export function getGuestDetailsCall(clientId: number) {
  return request({
    method: 'get',
    url: `calendar/restaurant/client/${clientId}/details`,
  }).then((response) => response);
}

export function getSpecialOffersCall(startTime: moment.Moment) {
  const time = moment(startTime).utcOffset(0, true).format('YYYY-MM-DD HH:mm:ss');
  return request({
    method: 'get',
    url: `calendar/restaurant/special-offers/${time}`,
  }).then((response) => response);
}

export function getPaymentsCall(reservationId: number) {
  return request({
    method: 'get',
    url: `calendar/payments/${reservationId}`,
  }).then((response) => response);
}
