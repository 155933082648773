import axios, { Canceler } from 'axios';
import { request } from 'utils/http/api';

const CancelToken = axios.CancelToken;
// eslint-disable-next-line
let getFloorPlanCancel: Canceler;

export function getFloorPlanCall() {
  return request({
    method: 'GET',
    url: '/floor-plan/get',
    cancelToken: new CancelToken(function executor(c) {
      getFloorPlanCancel = c;
    }),
  }).then((response) => response);
}
