import { asyncRequest, baseUrl } from 'utils/http/api';
import { ENDPOINT_URLS } from 'constants/endpointUrls';
import { IUser } from 'types/app/users';

export const getProfileCall = async () => {
  return await asyncRequest({
    method: 'GET',
    baseURL: baseUrl,
    url: ENDPOINT_URLS.ACCOUNT.PROFILE.ROOT,
  });
};

export const updateProfileCall = async (data: IUser) => {
  return await asyncRequest({
    method: 'PUT',
    data,
    baseURL: baseUrl,
    url: ENDPOINT_URLS.ACCOUNT.PROFILE.ROOT,
  });
};
