import { asyncRequest, baseUrl } from 'utils/http/api';
import { ENDPOINT_URLS } from 'constants/endpointUrls';

export const getSpecialOffersCall = async () => {
  return await asyncRequest({
    method: 'GET',
    baseURL: baseUrl,
    url: `${ENDPOINT_URLS.RESTAURANT.SPECIAL_OFFERS}`,
  });
};
