import { request } from 'utils/http/api';
import axios, { Canceler } from 'axios';
import { NewNoteParams } from '../types/notes';

let getNotesCancel: Canceler;

export function getNotesCall(date: string) {
  const CancelToken = axios.CancelToken;

  getNotesCancel && getNotesCancel();

  return request({
    method: 'GET',
    url: `/calendar/notes/get?date=${date}`,
    cancelToken: new CancelToken(function executor(c) {
      getNotesCancel = c;
    }),
  });
}

export function getNotesRangeCall(startDate: string, endDAte: string) {
  return request({
    method: 'GET',
    url: `/calendar/notes/get?start_date=${startDate}&end_date=${endDAte}`,
  });
}

export function createNoteCall(note: NewNoteParams) {
  return request({ method: 'POST', url: '/calendar/notes/create', data: note });
}

export function updateNoteCall(id: string, note: NewNoteParams) {
  return request({ method: 'PATCH', url: `/calendar/notes/update/${id}`, data: note });
}

export function deleteNoteCall(id: string, day: string | null) {
  if (!!day) {
    return request({
      method: 'POST',
      url: '/calendar/notes-excluded-days/create',
      data: { note_id: id, day },
    });
  } else {
    return request({ method: 'DELETE', url: `/calendar/notes/delete/${id}` });
  }
}
