import { baseUrl, asyncRequest } from 'utils/http/api';
import { ENDPOINT_URLS } from 'constants/endpointUrls';
import { ITimeAndLanguageData } from 'types/app/timeAndLanguage';

export const updateTimeAndLanguageCall = async (data: ITimeAndLanguageData) => {
  return await asyncRequest(
    {
      method: 'PUT',
      data,
      baseURL: baseUrl,
      url: ENDPOINT_URLS.RESTAURANT.SETTINGS.TIME_AND_LANGUAGE,
    },
    true,
  );
};

export const getTimeAndLanguageCall = async () => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.RESTAURANT.SETTINGS.TIME_AND_LANGUAGE,
    },
    true,
  );
};
