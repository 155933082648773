import { asyncRequest, baseUrl } from 'utils/http/api';
import { ENDPOINT_URLS } from 'constants/endpointUrls';

export const getOpeningHoursCall = async () => {
  return await asyncRequest({
    method: 'GET',
    baseURL: baseUrl,
    url: ENDPOINT_URLS.RESTAURANT.SETTINGS.OPENING_HOURS,
  });
};
